.teleconsult-container {
  position: relative;
  display: flex;
  align-items: end;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 40px;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #0bb54f;
  margin: 140px;
  margin-inline: 15%;
  padding-left: 2%;
}

.teleconsult-icon-wrapper {
  position: absolute;
  top: -40px;
  right: 30%;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.teleconsult-icon {
  width: 280%;
  height: auto;
}

.teleconsult-content {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.teleconsult-header {
  color: #d9c29d;
  font-size: 36px;
  margin-bottom: 30px;
}

.call-now-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #ffffff;
  border: 2px solid #0bb54f;
  color: #000000;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.call-now-button:hover {
  background-color: #0bb54f;
  color: #ffffff;
  border-color: #0bb54f;
  box-shadow: 0px 4px 8px rgba(11, 181, 79, 0.2);
  transform: scale(1.05);
}

.tele-call-now {
  color: #000;
  text-decoration: none;

}

.teleconsult-hours {
  color: #000;
}

.teleconsult-hours h3 {
  color: #d9c29d;
  margin: 16px 0;
  font-size: 22px;
  font-weight: bold;
}

.teleconsult-hours ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.teleconsult-hours li {
  color: #000000;
  margin-bottom: 6px;
  font-size: 14px;
}

.teleconsult-image {
  margin: 0px;
  padding: 0px;
}

.teleconsult-image img {
  max-width: 80%;
  border-radius: 15px;
}

@media (max-width: 1024px) {
  .teleconsult-container {
    flex-direction: column;
    margin: 100px auto;
    padding: 5%;
    margin-inline: 10%;
  }

  .teleconsult-icon-wrapper {
    top: -40px; 
    right: 25%; 
    width: 80px;
    height: 80px;
  }

  .teleconsult-icon {
    width: 180%; 
  }

  .teleconsult-header {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .call-now-button {
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 20px;
  }

  .teleconsult-hours h3 {
    font-size: 20px;
  }

  .teleconsult-hours li {
    font-size: 13px;
  }

  .teleconsult-image img {
    max-width: 70%;
  }
}

@media (max-width: 768px) {
  .teleconsult-container {
    flex-direction:row;
    margin-inline: 5%;
  }

  .teleconsult-icon-wrapper {
    top: -30px; 
    right: 20%; 
    width: 70px;
    height: 70px;
  }

  .teleconsult-icon {
    width: 280%;
  }

  .teleconsult-header {
    font-size: 28px;
  }

  .call-now-button {
    font-size: 12px;
    padding: 7px 10px;
  }

  .teleconsult-hours h3 {
    font-size: 18px;
  }

  .teleconsult-hours li {
    font-size: 12px;
  }

  .teleconsult-image img {
    max-width: 90%;
  }
}

@media (max-width: 480px) {
  .teleconsult-container {
    padding: 3%;
    margin-inline: 5%;
    flex-direction: column;
  }

  .teleconsult-content {
    margin-top: 30px;
  }

  .teleconsult-image {
    display: flex;
    justify-content: end;
  }
  .teleconsult-icon-wrapper {
    top: -20px; 
    right: 10%;
    width: 60px;
    height: 60px;
  }

  .teleconsult-header {
    font-size: 24px;
    margin-bottom: 15px;
  }

  .call-now-button {
    font-size: 10px;
    padding: 8px 14px;
  }

  .teleconsult-hours h3 {
    font-size: 16px;
  }

  .teleconsult-hours li {
    font-size: 11px;
  }

  .teleconsult-image img {
    max-width: 50%;
  }
}
