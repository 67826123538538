.pharmacy-medications-section {
  margin-top: 80px;
  padding-inline: 60px;
  padding-block: 0px;
  border-radius: 16px;
  background: linear-gradient(
    to bottom,
    #f1f0f0,
    #f8f8f8 65%,
    #e2e2e2 85%,
    rgba(255, 255, 255, 0) 95%
  );
}

.medications {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.medications-content {
  max-width: 50%;
  flex: 1;
  padding: 20px;
}

.medications-content h1 {
  font-size: 40px;
  font-weight: 700;
  color: #333;
  margin: 0;
  line-height: 1.3;
}

.medications-content p {
  font-size: 20px;
  color: #555;
  margin: 15px 0;
}

.medications-image {
  padding: 20px;
  position: relative;
  overflow: hidden;
}

.medications-image img {
  max-width: 100%;
  height: auto;
  border-radius: 16px;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .pharmacy-medications-section {
    padding-inline: 30px;
    padding-block: 60px;
  }

  .medications {
    flex-direction: column;
    padding: 20px;
  }

  .medications-content {
    max-width: 90%;
    text-align: center;
  }

  .medications-content h1 {
    font-size: 32px;
  }

  .medications-content p {
    font-size: 22px;
  }

  .medications-image img {
    max-width: 80%;
  }
}

@media (max-width: 480px) {
  .pharmacy-medications-section {
    padding-inline: 15px;
    padding-block: 40px;
  }

  .medications {
    flex-direction: column;
    padding: 15px;
  }

  .medications-content {
    max-width: 100%;
    padding: 0;
  }

  .medications-content h1 {
    font-size: 24px;
    margin-top: -60px;
    margin-bottom: 20px;
  }

  .medications-content p {
    font-size: 22px;
    margin: 10px 0;
  }

  .medications-image img {
    max-width: 90%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .pharmacy-medications-section {
    padding-inline: 40px;
    padding-block: 50px;
  }

  .medications {
    flex-direction: row;
    gap: 20px;
  }

  .medications-content {
    max-width: 70%;
    padding: 15px;
  }

  .medications-content h1 {
    font-size: 36px;
    line-height: 1.2;
  }

  .medications-content p {
    font-size: 18px;
  }

  .medications-image {
    padding: 15px;
  }

  .medications-image img {
    max-width: 85%;
    border-radius: 12px;
  }
}
