.contact-information-container {
  margin: 14px;
  background-color: #209953;
  position: relative;
}

.background-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}


.background-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-information {
  position: relative;
  z-index: 1;
  padding: 20px;
  color: #dfcc9e;
  border-radius: 10px;
}


.contact-details {
  margin-top: 20px;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.contact-item .address-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.contact-link:hover {
  color: #dfcc9e;
}

.icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}


.clock-icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}

.social-media {
  display: flex;
  gap: 15px;
  margin-top: 20px;
}

.opening-hours h3 {
  font-size: 40px;
  font-weight: bold;
}

.opening-hours p {
  align-items: center;
  color: white;
  display: flex;
  margin: 0 0 15px;
  font-size: 0.8rem;
  justify-content: start;
}

.opening-hours p:hover {
  color: #dfcc9e;
  cursor: pointer;
}

.social-media-contact {
  text-align: center;
  margin-top: 20px;
}

.social-media-contact h3 {
  font-size: 40px;
  font-weight: bold;
}



.instagram-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.tiktok-icon {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.social-media-link {
  justify-content: start;
}


.instagram-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 8px;
}

.tiktok-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #ffffff; 
}

.instagram-text {
  font-size: 0.8rem;
  color: #ffffff;
  margin: 0;
}

.tiktok-text {
  font-size: 0.8rem;
  color: #ffffff;
  margin: 0;
}

.instagram-link:hover .instagram-text {
  color: #dfcc9e;
}

.itiktok:hover .tiktok-text {
  color: #dfcc9e;
}

@media (max-width: 1200px) {
  .contact-information {
    padding: 15px;
    font-size: 1rem;
  }

  .opening-hours h3,
  .social-media-contact h3 {
    font-size: 1.3rem;
  }

  .contact-link {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .contact-information {
    font-size: 18px;
  }
  .contact-information-container {
    background-color: transparent;
    margin-top: 15px;
    margin-bottom: 120px !important;
  }

  .contact-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .social-media {
    flex-direction: column;
    gap: 10px;
  }

  .contact-icon,
  .clock-icon {
    width: 18px;
    height: 18px;
  }

  .instagram-icon {
    width: 20px;
    height: 20px;
  }

  .contact-details {
    font-size: 16px;
  }

}

@media (max-width: 768px) {
  .contact-information-container {
    margin-bottom: 250px;
    margin-top: 20px;
  }
  
  .contact-information {
    padding: 15px;
  }

  .opening-hours h3,
  .social-media-contact h3 {
    font-size: 1.2rem;
  }

  .contact-link {
    font-size: 0.93rem !important;
  }

  .contact-icon, .clock-icon {
    width: 20px;
    height: 20px;
  }
  a.contact-link {
    margin-left: 12px;
  }

  .instagram-icon {
    width: 25px;
    height: 25px;
  }

  .opening-hours p {
    margin-left: 50px;
  }

  .social-media-link {
    margin-left: 50px;
  }
  
  .contact-details {
    margin-left: 50px;
    font-size: 14px;
  }

}

@media (min-width: 768px) and (max-width: 1024px) {
  .contact-information-container {
    margin: 10px; 
  }

  .contact-information {
    padding: 15px; 
  }

  .contact-details {
    margin-top: 15px; 
  }

  .contact-item {
    flex-direction: column; 
    align-items: flex-start; 
    margin-bottom: 10px; 
  }

  .opening-hours h3,
  .social-media-contact h3 {
    font-size: 1.5rem; 
  }

  .contact-link {
    font-size: 1rem; 
  }

  .instagram-icon,
  .tiktok-icon {
    width: 25px; 
    height: 25px;
  }
}
