.header-content {
  display: flex;
  top: 0;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: transparent;
  width: 100%;
  height: 6.2%;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 1000;
  position: fixed;
  backdrop-filter: blur(4px);
  font-family: "Montserrat", sans-serif;
}

.header-content.scrolled {
  background: #fcfcff;
  box-shadow: none;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #dfcc9e;
}

.burger-menu {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 20px;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  position: relative;
  transition: transform 0.3s ease;
}

.burger-bar {
  width: 100%;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
}

.burger-menu.open .burger-bar:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger-menu.open .burger-bar:nth-child(2) {
  opacity: 0;
}

.burger-menu.open .burger-bar:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

nav {
  display: flex;
  flex: 1;
  margin-left: 160px;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  gap: 60px;
  align-items: center;
}

.nav-item {
  position: relative;
  font-size: 28px;
}

nav ul li {
  display: inline-block;
}

nav ul li a {
  text-decoration: none;
  font-size: 0.9em;
  padding: 10px;
  display: block;
  color: #333333;
}

nav ul li a:hover {
  color: rgb(32, 153, 83);
}

.products {
  padding: 10px;
  margin: 20px;
  display: flex;
  width: 100%;
  gap: 15%;
}

.concret-product {
  width: 10rem;
}

.product-container {
  text-align: center;
  margin-bottom: 20px;
}

.product-container img {
  display: block;
  margin: 0 auto;
}

.product-container p {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

.call-section {
  display: flex;
}

.contact-us-btn {
  background-color: rgb(32, 153, 83);
  color: white;
  border: 2px solid transparent;
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  position: relative;
}

.contact-us-btn:hover {
  background-color: #dfcc9e;
  transform: translateY(-2px);
  color: white;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: 70px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  z-index: 1000;
  position: fixed;
  border-bottom: 2px solid #dfcc9e;
  font-family: "Montserrat", sans-serif;
}

.header-content.scrolled {
  background-color: #fcfcff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.phone-icon {
  height: 25px;
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.call-section:hover .phone-icon {
  transform: rotate(10deg);
}

nav ul li a {
  text-decoration: none;
  padding: 10px;
  color: #347c57;
  transition: color 0.3s ease, transform 0.3s ease;
}

nav ul li a:hover {
  color: #dfcc9e;
  transform: scale(1.05);
}

.contact-us-btn {
  background-color: rgb(32, 153, 83);
  color: white;
  border-radius: 50px;
  padding: 10px 25px;
  font-weight: bold;
  text-transform: uppercase;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.contact-us-btn:hover {
  background-color: #dfcc9e;
  transform: scale(1.1);
}

.phone-icon {
  height: 25px;
  margin-right: 10px;
  transition: transform 0.3s ease;
}

.call-section:hover .phone-icon {
  transform: rotate(10deg);
}

@media (max-width: 768px) {
  .logo-name {
    font-size: 24px;
  }

  .header-logo {
    width: 120px; 
  }
}

@media (max-width: 480px) {
  .logo-name {
    font-size: 20px; 
  }

  .header-logo {
    width: 100px; 
  }
}

@media (max-width: 768px) {
  .header-content {
    background-color: rgba(255, 255, 255, 0.7);
    position: fixed;
    padding: 15px 0px;
  }

  .header-logo {
    margin-left: 0px !important;
  }

  .header-content.scrolled {
    background: rgba(255, 255, 255, 0.7);
    box-shadow: none;
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    border-radius: 10px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    border: 1px solid #dfcc9e;
    position: fixed;
  }

  .burger-menu {
    background-color: #cbb083;
    display: flex;
    margin-right: 40px;
  }

  .nav {
    display: none;
    position: absolute;
    top: 74px;
    right: 0;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .nav.open {
    background-color: rgba(245, 242, 242, 0.7);
    display: flex;
  }
  
  .nav ul {
    flex-direction: column;
    padding: 0;
    margin: 0;
    gap: 0;
  }
  
  .nav-item {
    text-align: center;
    width: 100%;
  }
  
  .nav ul li a {
    align-items: center;
    justify-content: center;
    background: none;
    padding: 15px 20px;
    color: #333;
    font-size: 18px;
  }

  .call-section {
    display: flex;
    align-items: center;
  }

  .contact-us-btn {
    color: white;
    padding: 10px 25px;
    font-weight: normal;
    text-transform: none;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .header-content {
    padding: 15px 20px;
    background-color: rgba(255, 255, 255, 0.8);
    height: 70px;
  }

  .header-logo {
    width: 100px;
    margin-left: 15px;
  }

  .logo-name {
    font-size: 24px; 
  }

  .burger-menu {
    background-color: #cbb083;
    display: flex;
    margin-right: 30px;
  }

  .nav {
    display: none;
    position: absolute;
    top: 70px;
    right: 0;
    flex-direction: column;
    background-color: #ffffff;
    width: 100%;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  .nav.open {
    display: flex;
  }

  .nav ul {
    flex-direction: column;
    padding: 0;
    margin: 0;
    gap: 10px;
  }

  .nav-item {
    text-align: center;
    width: 100%;
  }

  .nav ul li a {
    padding: 15px;
    font-size: 20px;
  }

  .call-section {
    padding-left: 20px;
    flex-direction: row;
    align-items: center;
  }

  .contact-us-btn {
    padding: 10px 25px;
    font-size: 16px;
  }
}
