.general-content {
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-section-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 20px 0;
  width: 100%;
  position: relative;
  top: -10px;
}

.product-section {
  display: flex;
  gap: 30px;
  width: max-content;
  animation: scrollProducts 25s linear infinite;
}

@keyframes scrollProducts {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-20%);
  }
}

.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  height: 120px;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 10px;
  text-align: left;
  transition: transform 0.3s ease;
  position: relative;
}

.product-card:hover {
  transform: translateY(-10px);
}

.product-card img {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 10px;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.product-card h3 {
  font-size: 18px;
  margin: 0 0 10px 0;
}

.product-card p {
  font-size: 16px;
  color: #666;
  margin: 0 0 0 100px;
  padding: 10px 10px 10px 0;
}

.pharmacy-essentials {
  font-size: 50px;
  background: white;
  color: #dfcc9e;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}